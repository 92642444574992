import React from "react";
import ReactDOM from "react-dom";
import "vercel-toast/css";
import { ConfigCatProvider } from "configcat-react";
import { BrowserRouter } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { QueryClient, QueryClientProvider } from "react-query";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { params } from "./params";
import "./theme.scss";
import { GeistContainer } from "./components/geistContainer";

const queryClient = new QueryClient();

(async () => {
  ReactDOM.render(
    <React.StrictMode>
      <ConfigCatProvider sdkKey={params.FEATURE_FLAG_ID}>
        <Auth0Provider
          domain={params.AUTH0_DOMAIN}
          clientId={params.CLIENT_ID}
          authorizationParams={{
            redirect_uri: window.location.origin,
          }}
        >
          <QueryClientProvider client={queryClient}>
            <GeistContainer>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </GeistContainer>
          </QueryClientProvider>
        </Auth0Provider>
      </ConfigCatProvider>
    </React.StrictMode>,
    document.getElementById("root") as HTMLElement
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
