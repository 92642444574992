import React, { useEffect, useMemo, useState } from "react";
import { Button, Card, Select, Text } from "@geist-ui/core";
import styles from "./styles.module.scss";
import { useReleases } from "../context/release";
import { useInstructions } from "../context/instructions";
import {
  ManualInstruction,
  ReleaseConversation,
  ReleaseConversationFeedback,
  TTopic,
} from "../types";
import { useGetTopics } from "../../../api/useGetTopics";
import cx from "classnames";
import {
  CheckInCircleFill,
  ThumbsDown,
  ThumbsUp,
  XCircleFill,
} from "@geist-ui/icons";
import { GeistDrawer } from "../../../components/geistDrawer";
import { Collapsable } from "../../../components/collapsable";
import { GeistBadge } from "../../../components/atoms/geistBadge";

type ExtendedManualInstruction = ManualInstruction & { topicId: number };

export const ReleaseDrawer = ({
  isOpen,
  setOpen,
}: {
  isOpen: boolean;
  setOpen: (v: boolean) => void;
}) => {
  return (
    <GeistDrawer
      openDrawer={isOpen}
      onDrawerClosed={() => setOpen(false)}
      headline={"Release"}
    >
      <Release closeDrawer={() => setOpen(false)} />
    </GeistDrawer>
  );
};

const Release = ({ closeDrawer }: { closeDrawer: () => void }) => {
  const { releaseConversations, handlePublish } = useReleases();
  const { data: topics }: { data: TTopic[] | undefined } = useGetTopics();
  const { manualInstructions, instructions } = useInstructions();

  const [topicsToExclude, setTopicsToExclude] = useState<string[]>([]);
  const [topicsNotEngaged, setTopicsNotEngaged] = useState<string[]>([]);

  const feedbackSummary = useMemo(() => {
    const summary: { good: number; bad: number; topics: number[] } = {
      good: 0,
      bad: 0,
      topics: [],
    };
    releaseConversations?.forEach((conversation) => {
      if (conversation.feedback === "POSITIVE") {
        if (!summary.topics.includes(conversation.topicId!)) {
          summary.topics.push(conversation.topicId!);
        }
        summary.good += 1;
      } else if (conversation.feedback === "NEGATIVE") {
        summary.bad += 1;
      }
    });
    return summary;
  }, [releaseConversations]);

  const instructionsWithTopicId = useMemo(() => {
    return manualInstructions.map((instruction) => {
      const topic = topics?.find(
        (topic) =>
          topic.topic.toLowerCase() === instruction.topic?.toLowerCase()
      );
      return {
        ...instruction,
        topicId: topic?.id ?? null,
      };
    });
  }, [manualInstructions, topics]);

  const groupedInstructions = useMemo(() => {
    return instructionsWithTopicId.reduce<
      Record<string, ExtendedManualInstruction[]>
    >((acc, instruction) => {
      const topic: string = instruction.topic!;
      if (!acc[topic]) {
        acc[topic] = [];
      }
      acc[topic].push(instruction as any);
      return acc;
    }, {});
  }, [instructionsWithTopicId]);

  const missingTopics = useMemo(() => {
    return feedbackSummary.topics.filter((topicId) => {
      const isMissing = !Object.keys(groupedInstructions).some((key) => {
        return groupedInstructions[key][0].topicId === topicId;
      });
      return isMissing;
    });
  }, [feedbackSummary.topics, groupedInstructions]);

  const handleExcludeInclude = (topic: string) => {
    if (topicsToExclude.includes(topic)) {
      setTopicsToExclude((prev) => prev.filter((x) => x !== topic));
    } else {
      setTopicsToExclude((prev) => [...prev, topic]);
    }
  };

  const [updatedGroupedInstructions] = useMemo(() => {
    const newGroupedInstructions = { ...groupedInstructions };
    const array: ExtendedManualInstruction[] = [];

    missingTopics.forEach((topicId) => {
      const topic = topics?.find((t) => t.id === topicId);
      if (topic) {
        const instruction: ExtendedManualInstruction = {
          topic: topic.topic,
          topicId: topicId,
          type: "manual",
          // its a magic - we want to present the user that there is not instructions here, but we want to add instruction because thats how the ai decide rather he should escalate or not
          content: "Think carefully before answering",
          subTopic: null,
        };
        array.push(instruction);
        newGroupedInstructions[topic?.topic] = [instruction];
      }
    });

    return [newGroupedInstructions, array];
  }, [missingTopics, topics, groupedInstructions]);

  useEffect(() => {
    if (topics) {
      setTopicsNotEngaged(
        topics
          ?.filter((t) => {
            return !updatedGroupedInstructions[t.topic];
          })
          .map((t) => t.topic)
      );
      setTopicsToExclude(
        topics
          ?.filter((t) => {
            return !updatedGroupedInstructions[t.topic];
          })
          .map((t) => t.topic)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topics, JSON.stringify(updatedGroupedInstructions)]);

  // engaged

  // skipped

  return (
    <div className={styles.container}>
      <Text h4>Create a Release</Text>
      <Text small>
        Review all topics scheduled for release. Unreviewed topics are skipped
        automatically but can be added to the release
      </Text>

      <Collapsable
        initialCollapsedValue={false}
        title={
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            Engaged
            <GeistBadge>
              {
                Object.keys(updatedGroupedInstructions).filter(
                  (topic) => !topicsToExclude.includes(topic)
                ).length
              }
            </GeistBadge>
          </div>
        }
      >
        <div className={styles.policyOverviewList}>
          {Object.keys(updatedGroupedInstructions).map((key) => {
            return (
              <InstructionSummary
                key={key}
                groupedInstructions={updatedGroupedInstructions}
                k={key}
                releaseConversations={releaseConversations}
                isIncluded={
                  !topicsToExclude.includes(
                    updatedGroupedInstructions[key]?.[0]?.topic
                  )
                }
                handleExcludeInclude={handleExcludeInclude}
              />
            );
          })}
        </div>
      </Collapsable>
      <Collapsable
        initialCollapsedValue={true}
        title={
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            Not Engaged
            <GeistBadge>{topicsNotEngaged!.length}</GeistBadge>
          </div>
        }
      >
        <div className={styles.policyOverviewList}>
          {topicsNotEngaged.map((key) => {
            return (
              <InstructionSummary
                isEmpty
                key={key}
                groupedInstructions={updatedGroupedInstructions}
                k={key}
                releaseConversations={releaseConversations}
                isIncluded={!topicsToExclude.includes(key)}
                handleExcludeInclude={handleExcludeInclude}
              />
            );
          })}
        </div>
      </Collapsable>
      <div className={styles.callout}>
        Need to release to specific channel or a segment? Contact Support
      </div>
      <div className={styles.bottom}>
        <div className={styles.countStat}>
          <span>{topicsToExclude.length}</span>
          <div className={styles.skip}>
            <XCircleFill />
            Skip
          </div>
        </div>
        <div className={styles.countStat}>
          <span>{topics!.length - topicsToExclude.length}</span>
          <div className={styles.stage}>
            <CheckInCircleFill />
            Staged for release
          </div>
        </div>

        <Button
          type="secondary"
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          width={2}
          onClick={() => {
            const generalInstructions = instructions.filter(
              (x) => x.type === "general"
            );

            let engagedInstructionsToRelease: ManualInstruction[] = [];
            // eslint-disable-next-line array-callback-return
            Object.keys(updatedGroupedInstructions).map((topic) => {
              if (!topicsToExclude.includes(topic)) {
                engagedInstructionsToRelease = [
                  ...engagedInstructionsToRelease,
                  ...updatedGroupedInstructions[topic],
                ];
              }
            });

            const topicsToInclude = topics
              ?.filter((t) => !topicsToExclude.includes(t.topic))
              .map((t) => t.topic);

            let notEngagedInstructionsToRelease: ManualInstruction[] = [];
            // eslint-disable-next-line array-callback-return
            topicsNotEngaged.map((topic) => {
              if (topicsToInclude?.includes(topic)) {
                notEngagedInstructionsToRelease.push({
                  content: "Think carefully before answering",
                  topic: topic,
                  subTopic: null,
                  type: "manual",
                });
              }
            });

            handlePublish(
              [
                ...generalInstructions,
                ...engagedInstructionsToRelease,
                ...notEngagedInstructionsToRelease,
              ],
              closeDrawer
            );
          }}
        >
          Release
        </Button>
      </div>
    </div>
  );
};

const InstructionSummary = ({
  k,
  groupedInstructions,
  releaseConversations,
  isIncluded,
  handleExcludeInclude,
  isEmpty = false,
}: {
  k: string;
  groupedInstructions: Record<string, ExtendedManualInstruction[]>;
  releaseConversations: ReleaseConversation[];
  isIncluded: boolean;
  handleExcludeInclude: (topic: string) => void;
  isEmpty?: boolean;
}) => {
  const feedbackSummary = useMemo(() => {
    const summary = { good: 0, bad: 0, total: 0 };
    if (isEmpty) {
      return summary;
    }
    releaseConversations
      ?.filter(
        (conversation: ReleaseConversation) =>
          conversation.topicId === groupedInstructions[k][0].topicId
      )
      .forEach((conversation: ReleaseConversation) => {
        summary.total += 1;
        if (conversation.feedback === ReleaseConversationFeedback.POSITIVE) {
          summary.good += 1;
        } else if (
          conversation.feedback === ReleaseConversationFeedback.NEGATIVE
        ) {
          summary.bad += 1;
        }
      });
    return summary;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [releaseConversations]);

  return (
    <Card key={k} className={cx(styles.instructionsCard)}>
      <div className={styles.informationContainer}>
        <Text h6>{k}</Text>
        {isEmpty ? (
          <div style={{ display: "flex", gap: "10px" }}>
            <Text small>Not engaged yet</Text>
          </div>
        ) : (
          <div className={styles.stats}>
            <div className={styles.countStat}>
              <span>{feedbackSummary.total}</span>
              <Text small>Conversatin Engaged</Text>
            </div>
            <div className={styles.countStat}>
              <span>
                {
                  groupedInstructions[k].filter(
                    (x) => x.content !== "Think carefully before answering"
                  ).length
                }
              </span>
              <Text small>Instructions</Text>
            </div>
            <div className={styles.countStat}>
              <span className={styles.negative}>{feedbackSummary.bad}</span>
              <Text small>
                {" "}
                <ThumbsDown /> bad
              </Text>
            </div>
            <div className={styles.countStat}>
              <span className={styles.positive}>{feedbackSummary.good}</span>
              <Text small>
                <ThumbsUp /> good
              </Text>
            </div>
          </div>
        )}
      </div>
      <div className={styles.cta}>
        <Select
          value={isIncluded ? "include" : "exclude"}
          dropdownClassName={styles.dropdown}
          className={styles.select}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          onChange={(value) => {
            handleExcludeInclude(k);
          }}
        >
          <Select.Option value="include">
            <div className={styles.stage}>
              <CheckInCircleFill />
              Staged for release
            </div>
          </Select.Option>
          <Select.Option value="exclude">
            <div className={styles.skip}>
              <XCircleFill />
              Skip
            </div>
          </Select.Option>
        </Select>
      </div>
    </Card>
  );
};
