import { useAuthorizedMutation, useAuthorizedQuery } from "..";
import { params } from "../../params";

export const useSamples = (enabled: boolean = true) => {
  return useAuthorizedQuery({
    queryKey: "sampling",
    url: `${params.API_URL}/platform/sampling/samples`,
    method: "GET",
    enabled,
  });
};

export const useSample = (sampleId: string, enabled: boolean = true) => {
  return useAuthorizedQuery({
    queryKey: `sampling-${sampleId}`,
    url: `${params.API_URL}/platform/sampling/samples/${sampleId}`,
    method: "GET",
    enabled,
  });
};

export const useTicketsBySample = (
  ticketId: string,
  sampleId: string,
  enabled: boolean = true
) => {
  return useAuthorizedQuery({
    queryKey: `sampling tickets-${sampleId}-${ticketId}`,
    url: `${params.API_URL}/platform/sampling/tickets/${ticketId}/${sampleId}`,
    method: "GET",
    enabled,
  });
};

export const useMutateScore = ({ scoreId }: { scoreId: number | null }) => {
  return useAuthorizedMutation({
    url: `${params.API_URL}/platform/sampling/ticketScores/${scoreId}`,
    method: "PUT",
  });
};

export const useSkipTicketScore = ({
  sampleId,
  ticketId,
}: {
  sampleId: string;
  ticketId: string;
}) => {
  return useAuthorizedMutation({
    url: `${params.API_URL}/platform/sampling/samples/${sampleId}/tickets/${ticketId}`,
    method: "DELETE",
  });
};
