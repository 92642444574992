import React, { Fragment, ReactNode, useEffect, useRef, useState } from "react";
import { ChevronDown, ChevronRight } from "@geist-ui/icons";
import cx from "classnames";

import styles from "./styles.module.scss";

const Frame = ({ children }: { children: ReactNode }) => {
  return <div className={styles.bg}>{children}</div>;
};

export const Collapsable = ({
  children,
  initialCollapsedValue = true,
  title,
  isFullWidth = false,
  isChildrenInSection = false,
}: {
  children: ReactNode;
  initialCollapsedValue: boolean;
  title: string | ReactNode;
  isFullWidth?: boolean;
  isChildrenInSection?: boolean;
}) => {
  const [isCollapsed, setCollapsed] = useState(initialCollapsedValue);
  const sectionRef = useRef<HTMLDivElement>(null);

  const Wrapper = isChildrenInSection ? Frame : Fragment;
  useEffect(() => {
    if (!isCollapsed && sectionRef.current) {
      const scrollToSection = () => {
        sectionRef.current?.scrollIntoView({ behavior: "smooth" });
      };
      scrollToSection();
    }
  }, [isCollapsed]);

  return (
    <Wrapper>
      <div
        ref={sectionRef}
        className={cx(styles.title, styles.clickable, {
          [styles.fullWidth]: isFullWidth,
        })}
        onClick={() => {
          setCollapsed((prev) => !prev);
        }}
      >
        {isCollapsed ? <ChevronRight /> : <ChevronDown />}
        {title}
      </div>
      {!isCollapsed && <div className={styles.children}>{children}</div>}
    </Wrapper>
  );
};
