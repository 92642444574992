import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Release,
  ReleaseCondition,
  ReleaseConversation,
  ReleaseComment,
  ReleaseStatus,
  Instruction,
  ReleaseConversationFeedback,
} from "../types";
import {
  useCreateNewRelease,
  useCreateNewReleaseConversation,
  useGetReleaseConversations,
  useGetReleases,
  useUpdateRelease,
} from "../../../api/useRelease";
//@ts-ignore
import confetti from "canvas-confetti";

import { useAuth0 } from "@auth0/auth0-react";
import { createToast } from "vercel-toast";

interface ReleaseContextType {
  releases: Release[];
  relevantRelease: Release | null;
  setReleases: React.Dispatch<React.SetStateAction<Release[]>>;
  releaseConditions: ReleaseCondition[];
  setReleaseConditions: React.Dispatch<
    React.SetStateAction<ReleaseCondition[]>
  >;
  releaseConversations: ReleaseConversation[];
  releaseComments: ReleaseComment[];
  setReleaseComments: React.Dispatch<React.SetStateAction<ReleaseComment[]>>;
  handleUpdateReleaseInstructions: (instructions: any) => void;
  handleUpdateConversation: (object: {
    localConversationId: number;
    message: { role: "user" | "assistant"; content: string };
    topicId: number;
    feedback: ReleaseConversationFeedback;
    feedbackComment?: string;
  }) => void;
  conversationId: number | null;
  handlePublish: (instructionsToPublish: Instruction[], cb: () => void) => void;
}

const ReleaseContext = createContext<ReleaseContextType | undefined>(undefined);

export const useReleases = (): ReleaseContextType => {
  const context = useContext(ReleaseContext);
  if (!context) {
    throw new Error("useReleases must be used within a ReleaseProvider");
  }

  return context;
};

export const ReleaseProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { user } = useAuth0();

  const [releases, setReleases] = useState<Release[]>([]);
  const [releaseConditions, setReleaseConditions] = useState<
    ReleaseCondition[]
  >([]);

  const [releaseComments, setReleaseComments] = useState<ReleaseComment[]>([]);
  const [releaseId, setReleaseId] = useState(0);
  const [conversationId, setConversationId] = useState<number | null>(null);

  const { data, refetch } = useGetReleases(!!user);

  const { mutateAsync: createNewRelease } = useCreateNewRelease();
  const { mutateAsync: updateRelease } = useUpdateRelease(releaseId);
  //   currently we're working on the draft release, later we'll add a list to choose which release
  const relevantRelease = useMemo(() => {
    return releases?.filter((r) => r.status === ReleaseStatus.draft)?.[0];
  }, [releases]);

  const { mutateAsync: upsertRelease } = useUpdateRelease(relevantRelease?.id);
  const { mutateAsync: upsertConversation } = useCreateNewReleaseConversation(
    relevantRelease?.id
  );
  const { data: releaseConversationsData, refetch: refetchConversations } =
    useGetReleaseConversations(!!relevantRelease?.id, relevantRelease?.id);

  useEffect(() => {
    if (data && data?.length === 0 && user) {
      // the timeout is here because a race condition with authorizedMutation
      setTimeout(() => {
        createNewRelease({
          id: null,
          releasePolicy: JSON.stringify([
            {
              content: 2,
              subTopic: "",
              topic: "maxBackAndForth",
              type: "general",
            },
            {
              content: "medium",
              subTopic: "",
              topic: "length",
              type: "general",
            },
            {
              content: true,
              subTopic: "",
              topic: "includeLink",
              type: "general",
            },
            {
              content: true,
              subTopic: "",
              topic: "includeOpening",
              type: "general",
            },
          ]),
          tenantId: user.owner,
          status: "draft",
        }).then((res) => {
          refetch();
        });
      }, 300);
    } else {
      setReleases(data);
      const published = data?.filter((r: Release) => r.status === "published");
      if (published?.length > 0) {
        setReleaseId(published[0].id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, data]);

  const handleUpdateReleaseInstructions = (
    instructions: Record<string, string[]>
  ) => {
    upsertRelease({
      id: relevantRelease.id,
      releasePolicy: JSON.stringify(instructions),
      tenantId: user!.owner,
      status: "draft",
    });
  };

  const handleUpdateConversation = ({
    localConversationId,
    message,
    topicId,
    feedback,
  }: {
    localConversationId: number;
    message: { role: "user" | "assistant"; content: string };
    topicId: number;
    feedback: "NONE" | "POSITIVE" | "NEGATIVE";
  }) => {
    if (topicId !== 0) {
      upsertConversation({
        releaseConversationId: localConversationId ?? 0,
        comment: message.content,
        topicId: topicId,
        feedback: feedback,
        isAgent: message.role === "assistant",
        feedbackComment: "",
        releaseId: relevantRelease.id,
      }).then((res) => {
        refetch();
        refetchConversations();
        setConversationId(res.id);
      });
    }
  };

  const handlePublish = (
    instructionsToPublish: Instruction[],
    callback: () => void
  ) => {
    updateRelease({
      id: releaseId,
      releasePolicy: JSON.stringify(instructionsToPublish),
      tenantId: user!.owner,
      status: "published",
    }).then((res) => {
      callback();
      createToast(`Your AI Agent is published!`, {
        type: "success",
        timeout: 3000,
      });

      confetti({
        particleCount: 300,
        spread: 300,
        origin: { y: 0.5, x: 0.4 },
      });
      refetch();
    });
  };

  return (
    <ReleaseContext.Provider
      value={{
        releases,
        setReleases,
        releaseConditions,
        setReleaseConditions,
        releaseConversations: releaseConversationsData ?? [],
        releaseComments,
        setReleaseComments,
        relevantRelease,
        handleUpdateReleaseInstructions,
        handleUpdateConversation,
        conversationId,
        handlePublish,
      }}
    >
      {children}
    </ReleaseContext.Provider>
  );
};
