import { useAuthorizedMutation, useAuthorizedQuery } from "../index";
import { params } from "../../params";

export const useGetReleases = (enabled: boolean) => {
  return useAuthorizedQuery({
    url: `${params.API_URL}/platform/releases`,
    queryKey: "get-releases",
    method: "GET",
    enabled,
  });
};

export const useCreateNewRelease = () => {
  return useAuthorizedMutation({
    url: `${params.API_URL}/platform/releases/0`,
    method: "PUT",
  });
};
export const useUpdateRelease = (releaseId: number) => {
  return useAuthorizedMutation({
    url: `${params.API_URL}/platform/releases/${releaseId}`,
    method: "PUT",
  });
};

export const useGetReleaseConversations = (
  enabled: boolean,
  releaseId: number
) => {
  return useAuthorizedQuery({
    url: `${params.API_URL}/platform/releases/${releaseId}/conversations`,
    queryKey: `get-release-conversations-${releaseId}`,
    method: "GET",
    enabled,
  });
};

export const useCreateNewReleaseConversation = (releaseId: number) => {
  return useAuthorizedMutation({
    url: `${params.API_URL}/platform/releases/${releaseId}/conversation`,
    method: "POST",
  });
};
