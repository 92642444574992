import React from "react";
import cx from "classnames";

import styles from "./styles.module.scss";

export const CircularChart = ({
  percentage,
  number,
  label,
  biggerPercentage,
  smallerPercentage,
}: {
  percentage: number;
  number?: string;
  label?: string;
  biggerPercentage?: number;
  smallerPercentage?: number;
}) => {
  return (
    <div className={styles.circleDiv}>
      <svg viewBox="0 0 36 36" className={styles.circleCont}>
        <path
          className={styles.circle}
          strokeDasharray={`100, 100`}
          d="M18 2.0845
      a 15.9155 15.9155 0 0 1 0 31.831
      a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <svg
          viewBox="0 0 36 36"
          className={cx(styles.circleCont, styles.active)}
        >
          <path
            className={styles.circle}
            strokeDasharray={`${percentage}, 100`}
            d="M18 2.0845
        a 15.9155 15.9155 0 0 1 0 31.831
        a 15.9155 15.9155 0 0 1 0 -31.831"
          />
        </svg>
      </svg>
      {biggerPercentage && (
        <span className={styles.biggerContainer}>
          <svg viewBox="0 0 36 36" className={styles.circleCont}>
            <path
              className={styles.circle}
              strokeDasharray={`100, 100`}
              d="M18 2.0845
      a 15.9155 15.9155 0 0 1 0 31.831
      a 15.9155 15.9155 0 0 1 0 -31.831"
            />
            <svg
              viewBox="0 0 36 36"
              className={cx(styles.circleCont, styles.active)}
            >
              <path
                className={styles.circle}
                strokeDasharray={`${biggerPercentage}, 100`}
                d="M18 2.0845
        a 15.9155 15.9155 0 0 1 0 31.831
        a 15.9155 15.9155 0 0 1 0 -31.831"
              />
            </svg>
          </svg>
        </span>
      )}
      {smallerPercentage && (
        <span className={styles.smallerContainer}>
          <svg viewBox="0 0 36 36" className={styles.circleCont}>
            <path
              className={styles.circle}
              strokeDasharray={`100, 100`}
              d="M18 2.0845
      a 15.9155 15.9155 0 0 1 0 31.831
      a 15.9155 15.9155 0 0 1 0 -31.831"
            />
            <svg
              viewBox="0 0 36 36"
              className={cx(styles.circleCont, styles.active)}
            >
              <path
                className={styles.circle}
                strokeDasharray={`${smallerPercentage}, 100`}
                d="M18 2.0845
        a 15.9155 15.9155 0 0 1 0 31.831
        a 15.9155 15.9155 0 0 1 0 -31.831"
              />
            </svg>
          </svg>
        </span>
      )}
      {number && label && (
        <div className={styles.detailsContianer}>
          <span className={styles.number}>{number}</span>
          <span className={styles.label}>{label}</span>
        </div>
      )}
    </div>
  );
};
