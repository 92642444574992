import React, { useCallback, useMemo, useState } from "react";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer";
import { useParams } from "react-router-dom";
import cx from "classnames";
import { Copy } from "@geist-ui/icons";
import { Tabs } from "@geist-ui/core";

import {
  useGetArticleDetails,
  useGetArticleOpportunities,
  useGetArticleTemplates,
  useImproveArticle,
} from "../../api/useWizard";
import { BigSelect } from "../../components/geistSelect";
import { EBadgeMode, GeistBadge } from "../../components/atoms/geistBadge";
import { useFetchingToasts } from "../../hooks/useFetchingToasts";

import styles from "./styles.module.scss";
import {
  ArticleDetails,
  ArticleGenerationTemplate,
  compareStyles,
  Opportunity,
} from "./index.types";

export const ArticleWizard = () => {
  const { articleId } = useParams<{ articleId: string }>();
  const [tab, setTab] = useState<"generated" | "compare">("generated");
  const [selectedOpportunityIds, setSelectedOpportunityIds] = useState<
    string[]
  >([]);
  const [isCopyPressed, setIsCopyPressed] = useState(false);
  const [instructions, setInstructions] = useState<string>("");
  const [selectedTemplateId, setSelectedTemplateId] = useState<string[]>([]);

  const {
    data: articleDetailsData,
    isLoading: isArticleDetailsLoading,
    refetch: refetchArticleDetails,
  } = useGetArticleDetails({
    articleId: articleId || "",
    enabled: !!articleId,
  });
  const articleDetails = articleDetailsData as ArticleDetails;

  const { data: opportunitiesData, isLoading: isOpportunitiesLoading } =
    useGetArticleOpportunities({
      articleId: articleId || "",
      enabled: !!articleId,
    });
  const opportunities = opportunitiesData as Opportunity[];

  const { data: templatesData, isLoading: isTemplatesLoading } =
    useGetArticleTemplates({ enabled: true });
  const templates = templatesData as ArticleGenerationTemplate[];

  const { currentArticleCoverage, opportunityCoverage } = useMemo(() => {
    if (!opportunities || !articleDetails)
      return { currentArticleCoverage: 0, opportunityCoverage: 0 };
    const suggestedArticleOpportunityIds = new Set(
      articleDetails?.suggestedArticles?.flatMap(
        (article) => article.opportunityIds
      ) || []
    );

    const totalTicketsCovered = opportunities
      .filter(
        (opportunity) =>
          suggestedArticleOpportunityIds.has(opportunity.id) &&
          opportunity.transaction.commonTransactionEval.isCovered
      )
      .reduce(
        (acc, opportunity) => acc + opportunity.transaction.tickets.length,
        0
      );

    const totalTicketsNotCovered = opportunities
      .filter(
        (opportunity) =>
          suggestedArticleOpportunityIds.has(opportunity.id) &&
          !opportunity.transaction.commonTransactionEval.isCovered
      )
      .reduce(
        (acc, opportunity) => acc + opportunity.transaction.tickets.length,
        0
      );

    return {
      currentArticleCoverage: totalTicketsCovered,
      opportunityCoverage: totalTicketsNotCovered,
    };
  }, [opportunities, articleDetails]);

  const {
    mutateAsync: improveArticle,
    isLoading: isImprovingArticle,
    data: improvingArticleData,
  } = useImproveArticle();

  useFetchingToasts({
    error: improvingArticleData?.statusCode > 400,
    emptyState: false,
    errorMessage: "Error while improving article, please try again",
  });

  const suggestedArticle = useMemo(() => {
    if (!articleDetails) return null;
    if (articleDetails?.suggestedArticles?.length === 0) return null;
    const sugg = articleDetails?.suggestedArticles?.[0];
    setSelectedOpportunityIds(sugg?.opportunityIds || []);
    setInstructions(sugg?.instructions || "");
    if (templates && templates.length > 0)
      setSelectedTemplateId([sugg.generationTemplateIds[0]?.toString()]);
    return sugg;
  }, [articleDetails, templates]);

  const loading = useMemo(() => {
    return (
      isArticleDetailsLoading ||
      isOpportunitiesLoading ||
      isImprovingArticle ||
      isTemplatesLoading ||
      !articleDetails ||
      !opportunities ||
      !templates
    );
  }, [
    isArticleDetailsLoading,
    isOpportunitiesLoading,
    isImprovingArticle,
    articleDetails,
    opportunities,
    isTemplatesLoading,
    templates,
  ]);

  const handleOpportunityChange = (opportunityId: string) => {
    setSelectedOpportunityIds((prevSelected) => {
      if (prevSelected.includes(opportunityId)) {
        return prevSelected.filter((id) => id !== opportunityId);
      } else {
        return [...prevSelected, opportunityId];
      }
    });
  };

  const handleImproveArticle = async () => {
    await improveArticle({
      articleId: articleId || "",
      instruction: instructions,
      opportunityIds: selectedOpportunityIds,
      templateId: selectedTemplateId.length
        ? Number(selectedTemplateId[0])
        : undefined,
    });
    refetchArticleDetails();
  };

  const activeButton = useMemo(() => {
    if (suggestedArticle) {
      const instructionsMatch = suggestedArticle.instructions || "";
      return (
        instructionsMatch !== instructions ||
        !selectedOpportunityIds.every((id) =>
          suggestedArticle.opportunityIds.includes(id)
        ) ||
        suggestedArticle.opportunityIds.length !== selectedOpportunityIds.length
      );
    }
    if (suggestedArticle === null) {
      return !!instructions || selectedOpportunityIds.length > 0;
    }
    return false;
  }, [selectedOpportunityIds, instructions, suggestedArticle]);

  const renderContent = useCallback((content: string) => {
    const isHTML = /<\/?[a-z][\s\S]*>/i.test(content);

    if (isHTML) {
      return <div dangerouslySetInnerHTML={{ __html: content }} />;
    } else {
      return content.split("\n").map((line: string, index: number) => {
        const parts = line.split(/(\*\*.*?\*\*)/); // Split by the bold markers

        return (
          <React.Fragment key={index}>
            {parts.map((part, i) => {
              if (part.startsWith("**") && part.endsWith("**")) {
                return <strong key={i}>{part.replace(/\*\*/g, "")}</strong>; // Remove '**' and bold the text
              } else {
                return <span key={i}>{part}</span>;
              }
            })}
            <br />
          </React.Fragment>
        );
      });
    }
  }, []);

  return (
    <div className={styles.mainContainer}>
      <div
        className={cx(styles.articleContainer, {
          [styles.compare]: tab === "compare",
        })}
      >
        <Tabs
          width="fit-content"
          value={tab}
          onChange={(value) => {
            setTab(value as "generated" | "compare");
          }}
        >
          <Tabs.Item label="Generated" value="generated">
            <></>
          </Tabs.Item>
          {!loading && suggestedArticle && (
            <Tabs.Item label="Compare" value="compare">
              <></>
            </Tabs.Item>
          )}
        </Tabs>
        <div
          className={cx(
            styles.article,
            { [styles.loading]: loading },
            { [styles.empty]: !suggestedArticle }
          )}
        >
          {loading ? (
            <div>Loading...</div>
          ) : tab === "generated" ? (
            <>
              <div className={cx(styles.content)}>
                {suggestedArticle ? (
                  <>
                    <div className={styles.copyBadgeContainer}>
                      <GeistBadge
                        mode={
                          isCopyPressed ? EBadgeMode.GREEN : EBadgeMode.DEFAULT
                        }
                        onClick={() => {
                          setIsCopyPressed(true);
                          navigator.clipboard.writeText(
                            suggestedArticle?.content || ""
                          );
                        }}
                      >
                        <Copy />
                        {isCopyPressed ? "Copied" : "Copy"}
                      </GeistBadge>
                    </div>
                    <h4>{suggestedArticle?.title}</h4>
                    <p>
                      {suggestedArticle?.content &&
                        renderContent(suggestedArticle.content)}
                    </p>{" "}
                  </>
                ) : (
                  <div className={styles.emptyState}>
                    No suggested article for article
                    <br />
                    <b>{articleDetails.title}</b>
                    <br />
                    <span>yet - you can generate one now!</span>
                  </div>
                )}
              </div>
            </>
          ) : (
            <ReactDiffViewer
              oldValue={articleDetails?.content.replace(/\n\n/g, "\n")}
              newValue={suggestedArticle?.content.replace(/\n\n/g, "\n")}
              compareMethod={DiffMethod.WORDS}
              splitView={true}
              hideLineNumbers={true}
              showDiffOnly={false}
              useDarkTheme={true}
              styles={compareStyles}
            />
          )}
        </div>
      </div>
      <div className={cx(styles.brainContainer, { [styles.loading]: loading })}>
        {!loading ? (
          <>
            <div className={styles.header}>
              <h2 className={styles.brainContainerHeader}>
                {articleDetails.title}
              </h2>
              <div className={styles.coverageContainer}>
                <div className={styles.currentCoverage}>
                  {currentArticleCoverage}
                </div>
                <div className={styles.opportunityCoverage}>
                  +{opportunityCoverage}
                </div>
                <div className={styles.coverageExplanation}>
                  Est. Tickets Coverage Score
                </div>
              </div>
            </div>
            <div className={styles.editorsContainer}>
              {templates &&
                Array.isArray(templates) &&
                templates.length > 0 && (
                  <div className={styles.editContainer}>
                    <h5>Select Template</h5>
                    <BigSelect
                      filter={selectedTemplateId}
                      setFilter={(value: any) => setSelectedTemplateId(value)}
                      options={templates.map((template) => ({
                        id: template.id,
                        name: template.name,
                      }))}
                      isLoading={false}
                      maxWidth="100%"
                    />
                  </div>
                )}
              <div className={styles.editContainer}>
                <h5>Insert</h5>
                <div className={styles.opportunityList}>
                  {opportunities &&
                    opportunities.map((opportunity) => (
                      <div
                        key={opportunity.id}
                        className={`${styles.opportunityItem} ${
                          selectedOpportunityIds.includes(opportunity.id)
                            ? styles.selected
                            : ""
                        }`}
                        onClick={() => handleOpportunityChange(opportunity.id)}
                      >
                        <input
                          type="checkbox"
                          checked={selectedOpportunityIds.includes(
                            opportunity.id
                          )}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleOpportunityChange(opportunity.id);
                          }}
                          className={styles.checkbox}
                        />
                        <label>{opportunity.label}</label>
                      </div>
                    ))}
                </div>
              </div>
              <div className={styles.editContainer}>
                <h5>Instructions</h5>
                <textarea
                  value={instructions}
                  onChange={(e) => {
                    setInstructions(e.target.value);
                  }}
                  placeholder="Add instruction "
                />
              </div>
            </div>
            <div className={styles.improveContainer}>
              <button
                className={cx(styles.improveButton, {
                  [styles.active]: activeButton,
                })}
                disabled={!activeButton}
                onClick={handleImproveArticle}
              >
                Improve
              </button>
            </div>
          </>
        ) : (
          <div>Loading...</div>
        )}
      </div>
    </div>
  );
};
