import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import cx from "classnames";
//@ts-ignore
import confetti from "canvas-confetti";
import { Message, useChat } from "ai/react";
import { Badge, Keyboard, Tabs, Text } from "@geist-ui/core";
import { ArrowUp, ThumbsDown, ThumbsUp } from "@geist-ui/icons";
import { IoSparkles } from "react-icons/io5";
import { InputType, SimulatorInput } from "../input";
import { useAuth0 } from "@auth0/auth0-react";
import dayjs from "dayjs";

import { Suggestions } from "../suggestions";
import styles from "./styles.module.scss";
import { Messages } from "../messages";
import { useInstructions } from "../context/instructions";
import { useGetTopics } from "../../../api/useGetTopics";
import { useReleases } from "../context/release";
import { params } from "../../../params";
import { ReleaseConversationFeedback } from "../types";

export const Chat = ({
  topic,
  setTopic,
  isTraining,
  setTraining,
}: {
  topic: string | null;
  setTopic: Dispatch<SetStateAction<string | null>>;
  isTraining: boolean;
  setTraining: Dispatch<SetStateAction<boolean>>;
}) => {
  const [id, setId] = useState(0);
  const { user } = useAuth0();

  const conversationIdRef = useRef<number>(null);
  const topicIdRef = useRef<number>(null);

  const { handleUpdateConversation, conversationId } = useReleases();

  useEffect(() => {
    (conversationIdRef as React.MutableRefObject<number | null>).current =
      conversationId;
  }, [conversationId]);

  // should create conversation
  // should create new release

  const [subTopic] = useState(null);

  const [textareaValue, setTextareaValue] = useState("");
  const { data: topics } = useGetTopics(!!user);
  const [isLoading, setLoading] = useState(false);
  const [type, setType] = useState<InputType>("none");

  const { instructions, handleInstruction } = useInstructions();

  const buttonRef = useRef<HTMLButtonElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleSuccessFeedback = () => {
    handleResetConversation(ReleaseConversationFeedback.POSITIVE);
    confetti({
      particleCount: 100,
      spread: 100,
      origin: { y: 0.5, x: 0.4 },
    });
  };

  const handleTopicAssignment = async (question: string) => {
    const response = await fetch(`${params.AI_URL}/api/topics`, {
      method: "POST",
      body: JSON.stringify({
        question: question,
        topics: topics
          ?.map((topic: { topic: string }) => topic.topic)
          .filter((topic: string) => topic.toLowerCase() !== "other"),
      }),
      headers: {
        "x-quack-token": params.AI_TOKEN,
      },
    });
    const data = await response.json();

    const topicId = topics.filter(
      (topic: { topic: string; id: number }) =>
        topic.topic.replace(/['"`*]/g, "").trim() ===
        data.text.replace(/['"`*]/g, "").trim()
    )?.[0]?.id;

    setTopic(data.text.replace(/['"`*]/g, "").trim());

    (topicIdRef as React.MutableRefObject<number | null>).current = topicId;

    handleUpdateConversation({
      message: { role: "user", content: question },
      feedback: ReleaseConversationFeedback.NONE,
      topicId: topicId ?? 0,
      localConversationId: conversationIdRef.current ?? 0,
    });

    return data.text.replace(/['"`*]/g, "").trim();
  };

  const handleFormSubmission = async (e: React.FormEvent<HTMLFormElement>) => {
    switch (type) {
      case "none":
        e.preventDefault();
        if (messages.length === 0) {
          await handleTopicAssignment(textareaRef?.current?.value ?? "");
        } else {
          // should update
          handleUpdateConversation({
            message: {
              role: "user",
              content: textareaRef?.current?.value ?? "",
            },
            feedback: ReleaseConversationFeedback.NONE,
            topicId: topicIdRef.current ?? 0,
            localConversationId: conversationId ?? 0,
          });
        }

        setLoading(true);
        handleSubmit(e);
        break;
      case "instruction":
        const inputValue = textareaRef?.current?.value ?? "";

        handleInstruction({
          topic,
          subTopic,
          content: inputValue,
          type: "manual",
        });
        e.preventDefault();

        setTimeout(() => {
          setTextareaValue("");
          setType("none");
          textareaRef!.current!.style.height = "24px";
        }, 300);

        break;
      case "feedback":
        e.preventDefault();

        handleResetConversation(
          ReleaseConversationFeedback.NEGATIVE,
          textareaRef?.current?.value ?? ""
        );

        setTimeout(() => {
          setTextareaValue("");
          setType("none");
          textareaRef!.current!.style.height = "24px";
        }, 300);

        break;
    }
  };

  const handleResetConversation = (
    feedback?:
      | ReleaseConversationFeedback.NEGATIVE
      | ReleaseConversationFeedback.POSITIVE,
    feedbackComment?: string
  ) => {
    // here should fire a create conversation
    handleUpdateConversation({
      localConversationId: conversationIdRef.current ?? 0,
      message: {
        role: "user",
        content: "--reset converesation--",
      },
      topicId: topicIdRef.current ?? 0,
      feedback: feedback ?? ReleaseConversationFeedback.NONE,
      feedbackComment: feedbackComment ?? "",
    });
    // should update the conversation
    (topicIdRef as { current: number | null }).current = null;
    (conversationIdRef as { current: number | null }).current = null;
    setTopic(null);
    setId(dayjs().valueOf());
  };

  const handleNegativeFeedback = () => {
    setType("feedback");
  };

  const handleResponse = async (response: Message) => {
    setLoading(false);
    const firstTool = response.toolInvocations?.[0];

    handleUpdateConversation({
      localConversationId: conversationIdRef.current ?? 0,
      message: {
        role: "assistant",
        content:
          response.content ||
          (firstTool?.state === "result" ? firstTool.result : "__"),
      },
      feedback: ReleaseConversationFeedback.NONE,
      topicId: topicIdRef.current ?? 0,
    });
  };

  const { messages, input, handleInputChange, handleSubmit, append, reload } =
    useChat({
      id: String(id),
      api: `${params.AI_URL}/api/reply?stream=true&playground=${isTraining}`,
      headers: {
        "x-quack-token": params.AI_TOKEN,
      },
      body: {
        tenant: user?.owner ?? "",
        instructions,
        topic,
        subTopic,
      },
      onFinish: handleResponse,
    });

  const [inputValue, handleInputValueChange] =
    type === "none"
      ? [input, handleInputChange]
      : [
          textareaValue,
          (
            e:
              | React.ChangeEvent<HTMLInputElement>
              | React.ChangeEvent<HTMLTextAreaElement>
          ) => setTextareaValue(e.target.value),
        ];

  const handleAddMessage = async (message: string) => {
    setLoading(true);
    await handleTopicAssignment(message);
    append({ content: message, role: "user" });
  };

  useEffect(() => {
    handleResetConversation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTraining]);

  const stringify = JSON.stringify(instructions);

  useEffect(() => {
    reload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringify]);

  return (
    <div className={styles.container}>
      <div className={styles.topContainer}>
        <Tabs
          hideBorder
          className={styles.tabs}
          leftSpace={0}
          align="center"
          hideDivider
          initialValue="training"
          onChange={(value) => setTraining(value === "training")}
          value={isTraining ? "training" : "simulate"}
        >
          <Tabs.Item value="training" label="Training"></Tabs.Item>
          <Tabs.Item value="simulate" label="Simulate"></Tabs.Item>
        </Tabs>
      </div>
      <div className={styles.messagesContainer}>
        {topic && (
          <div className={styles.badgesContainer}>
            <Badge width={10} className={styles.primary}>
              {topic}
            </Badge>
            {subTopic && (
              <Badge type="secondary" width={10}>
                {subTopic}
              </Badge>
            )}
          </div>
        )}
        {messages.length === 0 && !isLoading ? (
          <Suggestions handleMessage={handleAddMessage} />
        ) : (
          <Messages messages={messages} isLoading={isLoading} />
        )}
      </div>

      <form onSubmit={handleFormSubmission}>
        <div className={styles.inputContainer}>
          <div className={styles.feedbackContainer}>
            {messages.length > 1 && (
              <>
                <Text small>Rate the conversation</Text>
                <div>
                  <button
                    type="button"
                    className={cx(styles.btn, styles.positive)}
                    onClick={handleSuccessFeedback}
                  >
                    <ThumbsUp />
                  </button>
                  <button
                    type="button"
                    className={cx(styles.btn, styles.negative)}
                    onClick={handleNegativeFeedback}
                  >
                    <ThumbsDown />
                  </button>
                </div>
              </>
            )}
          </div>
          <div className={styles.inputFieldContainer}>
            <div className={styles.input}>
              <div className={styles.iconContainer}>
                <IoSparkles />
              </div>
              <SimulatorInput
                ref={textareaRef}
                type={type}
                setType={setType}
                value={inputValue}
                handleInputChange={handleInputValueChange}
                messagesLength={messages.length}
                handleSubmit={() => buttonRef.current?.click()}
                handleResetConversation={handleResetConversation}
              />
            </div>
            <div className={styles.submitContainer}>
              <button type="submit" ref={buttonRef}>
                <ArrowUp />
              </button>
            </div>
          </div>
          <div className={styles.bottomBar}>
            <div>
              <Keyboard scale={0.7} mr="10px">
                /
              </Keyboard>
              <Text small>to add instructions</Text>
            </div>
            <div
              className={styles.command}
              onClick={() => handleResetConversation()}
            >
              <Keyboard scale={0.7} command mr="10px">
                I
              </Keyboard>
              <Text small>new session</Text>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
