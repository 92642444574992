import { useAuthorizedQuery } from "..";
import { params } from "../../params";

export const useGetArticleDetails = ({
  articleId,
  enabled,
}: {
  articleId: string;
  enabled: boolean;
}) => {
  return useAuthorizedQuery({
    queryKey: `useGetArticleDetails-${articleId}`,
    url: `${params.API_URL}/knowledge-base-handler/articles/${articleId}`,
    method: "GET",
    enabled,
  });
};

export const useGetArticleOpportunities = ({
  articleId,
  enabled,
}: {
  articleId: string;
  enabled: boolean;
}) => {
  const statuses = "statuses[]=todo";
  const articleIds = `filterRelatedArticleIds[]=${articleId}`;
  return useAuthorizedQuery({
    queryKey: `useGetArticleOpportunities-${articleId}-${statuses}`,
    url: `${params.API_URL}/platform/opportunity?${statuses}&${articleIds}`,
    method: "GET",
    enabled,
  });
};

export const useGetArticleTemplates = ({ enabled }: { enabled: boolean }) => {
  return useAuthorizedQuery({
    queryKey: `useGetArticleTemplates`,
    url: `${params.API_URL}/knowledge-base-handler/article-generation-templates`,
    method: "GET",
    enabled,
  });
};
