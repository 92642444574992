import { ReactDiffViewerStylesOverride } from "react-diff-viewer";

export type ArticleDetails = {
  articleId: string;
  title: string;
  owner: string;
  status: string;
  url: string;
  author: string;
  topicIds: string[];
  createdAt: string;
  updatedAt: string;
  articleCategoryId: string | null;
  suggestedArticles: SuggestedArticle[];
  content: string;
};

export type SuggestedArticle = {
  id: number;
  title: string;
  owner: string;
  content: string;
  topicIds: string[];
  coveredTickets: string[];
  createdAt: string;
  updatedAt: string;
  tldr: string;
  changesTldr: string;
  updatedArticleId: string;
  opportunityIds: string[];
  instructions: string | null;
  generationTemplateIds: string[];
  ranking: string | null;
};

export type Opportunity = {
  id: string;
  label: string;
  status: string;
  tenantId: string;
  transactionId: number;
  type: string;
  updatedAt: string;
  createdAt: string;
  transaction: {
    id: number;
    tenantId: string;
    userContent: string;
    agentContent: string;
    createdAt: string;
    updatedAt: string;
    tickets: {
      id: string;
    }[];
    commonTransactionEval: {
      id: number;
      transactionId: number;
      tenantId: string;
      createdAt: string;
      updatedAt: string;
      isCovered: boolean;
      relatedArticlesIds: string[];
    };
  };
};

export type ArticleGenerationTemplate = {
  id: string;
  name: string;
  description: string;
  instructions: string;
  createdAt: string;
  updatedAt: string;
};

export const compareStyles: ReactDiffViewerStylesOverride = {
  diffContainer: {
    backgroundColor: "transparent",
  },
  diffRemoved: {
    backgroundColor: "transparent",
    opacity: 0.7,
  },
  diffAdded: {
    backgroundColor: "transparent",
  },
  emptyLine: {
    backgroundColor: "transparent",
  },
  marker: {
    padding: "0px",
  },
  contentText: {
    padding: "6px",
    fontFamily: "Geist",
    border: "none",
  },
  wordRemoved: {
    backgroundColor: "#FF6166",
    padding: "0 2px",
    borderRadius: "4px",
  },
  wordAdded: {
    backgroundColor: "#4d945a",
    padding: "0 2px",
    borderRadius: "4px",
  },
};
