import React, { useMemo } from "react";
import cx from "classnames";
import { useNavigate } from "react-router-dom";

import { useAutomationStatus } from "../../../../api/useAutomationStatus";
import { DashboardCard } from "../dashboardCard";
import { CircularChart } from "../../../../components/atoms/circleChart";
import {
  ESessionStorage,
  fetchFromSessionStorage,
} from "../../../../assets/storage/sessionStorage";
import { ETimesFilter } from "../../../../assets/types";

import styles from "./styles.module.scss";
import { TAutomationStatus } from "./index.types";

export const AutomationStatus = ({
  timeFilter,
}: {
  timeFilter: {
    value: ETimesFilter;
    label: string;
  };
}) => {
  const { data, isLoading: autoStatusLoading } = useAutomationStatus(
    timeFilter.value,
    !!timeFilter.value
  );
  const autoStatus = data as TAutomationStatus;
  const navigate = useNavigate();

  const loading = useMemo(
    () =>
      autoStatusLoading || (autoStatus?.tickets !== 0 && !autoStatus?.tickets),
    [autoStatus, autoStatusLoading]
  );

  const navigateToActivation = () => {
    const firstTopic = fetchFromSessionStorage(ESessionStorage.FIRST_TOPIC);
    navigate(`/activation/${firstTopic}`);
  };

  return (
    <DashboardCard
      smallHead="Automation Status"
      bigHead={`${Math.round(
        (autoStatus?.readyTickets * 100) / autoStatus?.tickets
      )}% automation potentioal across ${autoStatus?.topicsCount} topics`}
      expand={navigateToActivation}
      grow={5}
      loading={loading}
      loadingClass="autoStatus"
      maxHeight={535}
    >
      {!loading ? (
        <div className={styles.circleDetailsContainer}>
          <div className={styles.circleWrapper}>
            <CircularChart
              percentage={Math.max(
                0.1,
                autoStatus?.publishedTickets
                  ? Math.round(
                      (autoStatus?.publishedTickets * 100) / autoStatus?.tickets
                    )
                  : 0
              )}
              biggerPercentage={Math.max(
                0.1,
                autoStatus?.enabledTickets
                  ? Math.round(
                      (autoStatus?.enabledTickets * 100) / autoStatus?.tickets
                    )
                  : 0
              )}
              number={String(autoStatus?.topicsCount)}
              label={"Topics"}
              smallerPercentage={Math.max(
                0.1,
                autoStatus?.readyTickets
                  ? Math.round(
                      (autoStatus?.readyTickets * 100) / autoStatus?.tickets
                    )
                  : 0
              )}
            />
          </div>
          <div className={styles.dataWrapper}>
            <div className={cx(styles.dataLine, styles.published)}>
              <span className={styles.dot} /> Automation potential{" "}
              {Math.round(
                autoStatus?.readyTickets
                  ? (autoStatus?.readyTickets * 100) / autoStatus?.tickets
                  : 0
              )}
              %
            </div>
            <div className={cx(styles.dataLine, styles.potential)}>
              <span className={styles.dot} /> Published to Copilot{" "}
              {Math.round(
                autoStatus?.publishedTickets
                  ? (autoStatus?.publishedTickets * 100) / autoStatus?.tickets
                  : 0
              )}
              %
            </div>
            <div className={cx(styles.dataLine, styles.enabled)}>
              <span className={styles.dot} /> Automation enabled{" "}
              {Math.round(
                autoStatus?.enabledTickets
                  ? (autoStatus?.enabledTickets * 100) / autoStatus?.tickets
                  : 0
              )}
              %
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </DashboardCard>
  );
};
