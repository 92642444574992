import React, { Fragment, ReactElement, useEffect, useRef } from "react";
import cx from "classnames";
//@ts-ignore
import { Converter } from "showdown";
import { Message, ToolInvocation } from "ai";

import styles from "./styles.module.scss";
import { IoSparkles } from "react-icons/io5";
import { Loading } from "@geist-ui/core";
import { Info } from "@geist-ui/icons";

interface IMessage {
  role: "user" | "assistant";
  content: string;
}

enum Tools {
  SKIP_IT = "skip_it",
  TALK_TO_AN_AGENT = "talk_to_an_agent",
}

export const Messages = ({
  messages,
  isLoading,
}: {
  messages: Message[];
  isLoading: boolean;
}) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);

  // handle scrolling down on messages
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView();
  }, [messages]);

  return (
    <>
      {messages.length === 0 && <div>Analyzing...</div>}
      {messages.map((m, i) => {
        return (
          <MessageComponent
            role={m.role === "user" ? "user" : "assistant"}
            content={m.content}
            tools={m.toolInvocations}
          />
        );
      })}

      {isLoading && messages.length % 2 !== 0 && (
        <MessageComponent role={"assistant"} content={""} isLoading />
      )}

      <div ref={messagesEndRef} />
    </>
  );
};

const AgentWrapper = ({
  children,
  hideIcon = false,
}: {
  children: ReactElement;
  hideIcon?: boolean;
}) => {
  return (
    <div className={styles.assistantWrapper}>
      {!hideIcon && (
        <div className={styles.sparklesIcon}>
          <IoSparkles />
        </div>
      )}
      {children}
    </div>
  );
};

const convertor = new Converter({
  simplifiedAutoLink: true,
  excludeTrailingPunctuationFromURLs: true,
  openLinksInNewWindow: true,
});

const convertMarkdownToHTML = (markdown: string) => {
  if (markdown) {
    return convertor?.makeHtml(markdown);
  } else {
    return "";
  }
};

const MessageComponent = ({
  role,
  content,
  tools,
  isLoading = false,
}: IMessage & { isLoading?: boolean; tools?: ToolInvocation[] }) => {
  const Wrapper = role === "assistant" ? AgentWrapper : Fragment;

  const firstTool = tools?.[0];

  const isSkip =
    firstTool?.state === "result" && firstTool?.result === Tools.SKIP_IT;

  const isEscalated =
    firstTool?.state === "result" &&
    firstTool.result === Tools.TALK_TO_AN_AGENT;

  const props = role === "assistant" ? { hideIcon: isSkip || isEscalated } : {};

  return (
    <Wrapper {...props}>
      <div
        className={cx(styles.message, styles[role], {
          [styles.loading]: isLoading,
          [styles.systemMessage]: isSkip || isEscalated,
        })}
      >
        {isLoading ? (
          <Loading width={2.5} />
        ) : (
          <>
            {isSkip && (
              <div className={styles.system}>
                <Info />
                Out of the policy, Quack Agent will skip this question
              </div>
            )}
            {isEscalated && (
              <div className={styles.system}>
                <Info />
                Quack will escalate it to an agent
              </div>
            )}
            <div
              dangerouslySetInnerHTML={{
                __html: convertMarkdownToHTML(content),
              }}
            />
          </>
        )}
      </div>
    </Wrapper>
  );
};
