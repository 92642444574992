import React, { useEffect, useMemo, useState } from "react";
import cx from "classnames";
import styles from "./styles.module.scss";
import { Button, Text } from "@geist-ui/core";

import { ThumbsDown, ThumbsUp } from "@geist-ui/icons";
import { Instructions } from "../instructions";
import { ReleaseDrawer } from "../release";
import { useReleases } from "../context/release";

export const RigthPanel = ({
  topic,
  isTraining,
}: {
  topic: string | null;
  isTraining: boolean;
}) => {
  const [isOpen, setOpen] = useState(false);
  const { releaseConversations } = useReleases();

  const feedbackSummary = useMemo(() => {
    const summary: { good: number; bad: number; topics: number[] } = {
      good: 0,
      bad: 0,
      topics: [],
    };
    releaseConversations?.forEach((conversation) => {
      if (conversation.feedback === "POSITIVE") {
        if (!summary.topics.includes(conversation.topicId!)) {
          summary.topics.push(conversation.topicId!);
        }
        summary.good += 1;
      } else if (conversation.feedback === "NEGATIVE") {
        summary.bad += 1;
      }
    });
    return summary;
  }, [releaseConversations]);

  // const GoodFeedback: React.FC = () => (
  //   <div>
  //     <div>1</div>
  //     <div>1</div>
  //     <div>1</div>
  //     <div>1</div>
  //   </div>
  // );
  // const BadFeedback: React.FC = () => (
  //   <div>
  //     <div>2</div>
  //     <div>2</div>
  //     <div>2</div>
  //     <div>2</div>
  //   </div>
  // );

  return (
    <div className={styles.topContainer}>
      <div className={styles.topbar}>
        {/* <Popover content={GoodFeedback as any}> */}
        <FeedabckPanel type="good" count={feedbackSummary.good} />
        <FeedabckPanel type="bad" count={feedbackSummary.bad} />
        {/* </Popover> */}
        {/* <Popover content={BadFeedback as any}> */}
        {/* </Popover> */}

        <Button
          type="secondary"
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          onClick={() => setOpen(true)}
          scale={0.5}
          padding={0}
          font={1.2}
          width={0.8}
        >
          Create Release
        </Button>
      </div>
      <ReleaseDrawer isOpen={isOpen} setOpen={(v) => setOpen(v)} />
      {isTraining ? (
        <div
          style={{
            padding: "10px",
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Instructions topic={topic} />
        </div>
      ) : (
        <div className={styles.emptyStateContainer}>
          <Text h6>Simulating...</Text>
          <Text small>
            To make changes to the AI Brain, switch back to Training mode.
          </Text>
        </div>
      )}
    </div>
  );
};

const FeedabckPanel = ({
  type,
  count,
}: {
  type: "good" | "bad";
  count: number;
}) => {
  const [isChanged, setChanged] = useState(false);

  useEffect(() => {
    setChanged(true);
    setTimeout(() => {
      setChanged(false);
    }, 600);
  }, [count]);

  return (
    <div
      className={cx(styles.feedbackContainer, {
        [styles.animationPositive]: type === "good" && isChanged,
        [styles.animationNegative]: type === "bad" && isChanged,
      })}
    >
      {type === "good" ? <ThumbsUp /> : <ThumbsDown />}
      {count} {type}
    </div>
  );
};
