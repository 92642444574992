import React, { useEffect, useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Card, Keyboard, Select, Spacer, Text, Tooltip } from "@geist-ui/core";

import styles from "./styles.module.scss";
import { useInstructions } from "../context/instructions";
import { Info, Trash } from "@geist-ui/icons";
import { Instruction, ManualInstruction } from "../types";
import { Collapsable } from "../../../components/collapsable";
import { OptionsMapper, OptionsInfoMapper } from "./options";
import { EBadgeMode, GeistBadge } from "../../../components/atoms/geistBadge";

export const Instructions = ({ topic }: { topic: string | null }) => {
  const { user } = useAuth0();
  const { manualInstructions, generalInstructions, handleGeneralInstruction } =
    useInstructions();

  const groupedInstructions = (
    manualInstructions as ManualInstruction[]
  ).reduce<Record<string, ManualInstruction[]>>((acc, instruction) => {
    const { topic } = instruction;
    if (!acc[topic]) {
      acc[topic] = [];
    }
    acc[topic].push(instruction);
    return acc;
  }, {});

  const onGeneralChange = (value: string, key: string) => {
    handleGeneralInstruction(
      generalInstructions.map((x) => {
        if (x.topic === key) {
          return {
            ...x,
            content: value,
          };
        }
        return x;
      })
    );
  };

  if (topic) {
    return (
      <div className={styles.container}>
        <Text h4 style={{ textTransform: "capitalize" }}>
          {topic}
        </Text>
        <Spacer h={0.7} />
        <Text small>
          <div style={{ display: "flex", gap: "6px" }}>
            Instractions{" "}
            <GeistBadge mode={EBadgeMode.DEFAULT}>
              {groupedInstructions[topic]?.length ?? 0}
            </GeistBadge>
          </div>
        </Text>

        {(!groupedInstructions[topic] ||
          groupedInstructions[topic]?.length === 0) && (
          <div className={styles.emptyStateContainer}>
            <Text h5>No instructions has been submitted yet</Text>
            <Text small>
              Press{" "}
              <Keyboard scale={0.7} mr="10px">
                /
              </Keyboard>
              to add instructions
            </Text>
          </div>
        )}

        {groupedInstructions[topic]?.map((i, index) => {
          return (
            <InstructionCard instruction={i} index={index} key={i.content} />
          );
        })}
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <Text h4 style={{ textTransform: "capitalize" }}>
        {user?.owner}'s brain
      </Text>

      <div className={styles.instructionsScrollWrapper}>
        <Text small>General</Text>
        <Collapsable
          isFullWidth
          isChildrenInSection
          initialCollapsedValue
          title={
            <Text h6 style={{ textTransform: "capitalize", margin: "0" }}>
              Preferences
            </Text>
          }
        >
          <div className={styles.generalInstructionsContainer}>
            {generalInstructions.map((g) => {
              return (
                <div className={styles.generalInstruction}>
                  <div className={styles.generalInstructionLabel}>
                    <Text small>
                      {
                        OptionsInfoMapper[
                          g.topic as keyof typeof OptionsInfoMapper
                        ].title
                      }
                    </Text>
                    <Tooltip
                      text={
                        OptionsInfoMapper[
                          g.topic as keyof typeof OptionsInfoMapper
                        ].info
                      }
                      type="dark"
                    >
                      <Info />
                    </Tooltip>
                  </div>
                  <Select
                    value={String(g.content)}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                    className={styles.select}
                    onChange={(value) => {
                      onGeneralChange(value as string, g.topic!);
                    }}
                  >
                    {OptionsMapper[g.topic as keyof typeof OptionsMapper].map(
                      (option) => (
                        <Select.Option value={String(option)}>
                          {String(option)}
                        </Select.Option>
                      )
                    )}
                  </Select>
                </div>
              );
            })}
          </div>
        </Collapsable>
        <Text small>
          <div style={{ display: "flex", gap: "6px" }}>
            Instractions{" "}
            <GeistBadge mode={EBadgeMode.DEFAULT}>
              {manualInstructions.length}
            </GeistBadge>
          </div>
        </Text>
        {manualInstructions.length === 0 && (
          <div className={styles.emptyStateContainer}>
            <Text h5>Your training space awaits</Text>
            <Text small>
              This panel will collect instructions and feedback as you interact
              with the simulator.
            </Text>
          </div>
        )}
        {Object.keys(groupedInstructions).map((topic) => {
          return (
            <Collapsable
              initialCollapsedValue={true}
              title={
                <Text
                  h6
                  style={{
                    textTransform: "capitalize",
                    margin: "0",
                    display: "flex",
                    gap: "6px",
                    alignItems: "center",
                  }}
                >
                  {topic}{" "}
                  <GeistBadge mode={EBadgeMode.DEFAULT}>
                    {groupedInstructions[topic].length}
                  </GeistBadge>
                </Text>
              }
            >
              <div className={styles.instructionsContainer}>
                {groupedInstructions[topic].map((i, index) => {
                  return (
                    <InstructionCard
                      instruction={i}
                      index={index}
                      key={i.content}
                    />
                  );
                })}
              </div>
            </Collapsable>
          );
        })}
      </div>
    </div>
  );
};

const InstructionCard = ({
  instruction,
  index,
}: {
  instruction: Instruction;
  index: number;
}) => {
  const [, setHover] = useState<boolean>(false);
  const { deleteInstruction } = useInstructions();
  const cardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    cardRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <Card
      ref={cardRef}
      className={styles.card}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Text small>{instruction.content}</Text>
      {instruction.subTopic && (
        <div className={styles.badges}>
          <GeistBadge mode={EBadgeMode.BLUE}>{instruction.subTopic}</GeistBadge>
        </div>
      )}

      {instruction.type === "manual" && (
        <button
          className={styles.btn}
          onClick={() => deleteInstruction(instruction, index)}
        >
          <Trash />
        </button>
      )}
    </Card>
  );
};
