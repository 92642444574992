import { Box, Archive, Home, Layers, Settings, Speaker } from "@geist-ui/icons";
import { BsLightningCharge } from "react-icons/bs";
import { VscGraphLine } from "react-icons/vsc";
import { Crosshair } from "@geist-ui/icons";
import { Compass } from "@geist-ui/icons";

import { PiShapes } from "react-icons/pi";
import { ReactNode } from "react";

export enum ENavigate {
  HOME = "home",
  ASSESSMENT = "assessment",
  TICKETS = "tickets",
  ACTIVATION = "activation",
  SETTINGS = "settings",
  KNOWLEDGE = "knowledge-management",
  SAMPLING = "sampling",
  PERFORMANCE = "performance",
  VOC = "voice-of-customer",
  SIMULATOR = "simulator",
  SESSIONS_REPORTS = "sessions-reports",
}

export const PagesIcons: Record<ENavigate, ReactNode> = {
  [ENavigate.HOME]: <Home />,
  [ENavigate.ASSESSMENT]: <VscGraphLine />,
  [ENavigate.TICKETS]: <Layers />,
  [ENavigate.ACTIVATION]: <BsLightningCharge />,
  [ENavigate.SETTINGS]: <Settings />,
  [ENavigate.KNOWLEDGE]: <PiShapes />,
  [ENavigate.SAMPLING]: <Crosshair />,
  [ENavigate.PERFORMANCE]: <Compass />,
  [ENavigate.VOC]: <Speaker />,
  [ENavigate.SIMULATOR]: <Box />,
  [ENavigate.SESSIONS_REPORTS]: <Archive />,
};
