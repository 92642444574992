import React, { useEffect, useState } from "react";
import cx from "classnames";
import { useAuth0 } from "@auth0/auth0-react";
import { Card, Select, Text } from "@geist-ui/core";

import { useSimilarTickets } from "../../../api/useSimilarTicket";
import { useGetTopics } from "../../../api/useGetTopics";
import styles from "./styles.module.scss";
import { ArrowLeft, ArrowRight, Loader } from "@geist-ui/icons";
import { cleanTickets } from "./lib";

export const Suggestions = ({
  handleMessage,
}: {
  handleMessage: (value: string) => void;
}) => {
  const { user } = useAuth0();
  const [currentIndex, setCurrentIndex] = useState(0);

  const [selectedValue, setSelectedValue] = useState("");
  const { data: topicList, isLoading } = useGetTopics(!!user);

  const { data, isLoading: similarTicketsLoading } = useSimilarTickets(
    !!user,
    +selectedValue
  );

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? data.length - 3 : prevIndex - 3
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + 3 >= data.length ? 0 : prevIndex + 3
    );
  };

  useEffect(() => {
    setSelectedValue((prev) => {
      return String(
        topicList?.filter(({ topic }: { topic: string }) => {
          return topic.toLowerCase() !== "other";
        })?.[0]?.id
      );
    });
  }, [topicList]);

  return (
    <div className={styles.suggestionsContainer}>
      {isLoading ? (
        <Loader />
      ) : (
        <Select
          value={selectedValue}
          placeholder="Choose your topic"
          onChange={(selectedId) => {
            setSelectedValue(selectedId as string);
          }}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        >
          {topicList
            .filter(({ topic }: { topic: string }) => {
              return topic.toLowerCase() !== "other";
            })
            .map((topic: { topic: string; id: number }) => {
              return (
                <Select.Option value={String(topic.id)}>
                  {topic.topic}
                </Select.Option>
              );
            })}
        </Select>
      )}

      {data?.length !== 0 && !similarTicketsLoading ? (
        <>
          <div className={styles.title}>
            <Text small my={0}>
              Some good tickets to start with
            </Text>
          </div>
          <div className={cx(styles.cardContainer)}>
            {data
              ?.filter((t: any) => t?.id)
              .slice(currentIndex, currentIndex + 3)
              .map(
                (ticket: {
                  id: string;
                  description: string;
                  similarTicketsCount: number;
                }) => {
                  const cleanedDescription = cleanTickets(
                    ticket.description ?? ""
                  );
                  return (
                    <Card
                      key={ticket.id}
                      className={styles.card}
                      hoverable
                      onClick={() => handleMessage(cleanedDescription)}
                    >
                      <div>
                        <Text small>#{ticket.id}</Text>
                        <Text h6 my={0}>
                          {cleanedDescription}
                        </Text>
                      </div>
                      <div>
                        <Text small>
                          similar tickets - {ticket.similarTicketsCount}
                        </Text>
                      </div>
                    </Card>
                  );
                }
              )}
          </div>

          {data?.length > 3 && (
            <div className={styles.btnsContainer}>
              <button onClick={handlePrev} className={styles.btn}>
                <ArrowLeft />
              </button>
              <button onClick={handleNext} className={styles.btn}>
                <ArrowRight />
              </button>
            </div>
          )}
        </>
      ) : (
        <div className={styles.title}>
          <Text small my={0}>
            Oops, we didn't find any similar ticket, but feel free to ask
            anything 👇
          </Text>
        </div>
      )}
      {similarTicketsLoading && (
        <div className={cx(styles.cardContainer)}>
          <>
            <SuggestionsSkeleton />
          </>
        </div>
      )}
    </div>
  );
};

const SuggestionsSkeleton = () => {
  return (
    <>
      <div className={styles.skeletonCard}></div>
      <div className={styles.skeletonCard}></div>
      <div className={styles.skeletonCard}></div>
    </>
  );
};
